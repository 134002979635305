import { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { COUNTRIES } from '../constant/country';
import MyMenu from './MyMenu';
import { AuthAction, GroupAction } from "../redux/action";
import Header from "./Header";
import { history } from "../history";
import Loader from "./Loader";
import { useTranslation } from 'react-i18next';
import SettingAccountMarketing from "./SettingAccountMarketing";

const Profile = ({ group, GroupInfo }) => {
    const [t] = useTranslation(['translation']);
    const user = JSON.parse(localStorage.getItem('user'));

    const [showLoader, setShowLoader] = useState(false);
    const [country, setCountry] = useState('');

    useEffect(() => {
        if (!user) {
            history.push('/');
        } else {
            setShowLoader(true);
            GroupInfo(user?.groupId).then(response => {
                if (response.data?.countryCode)
                    setCountry(getCountryName(response.data.countryCode))
            });
            setShowLoader(false);
        }
    }, [user]);

    const getCountryName = (code) => {
        var c = COUNTRIES.find(x => x.countryCode === code);
        return c ? c.name : <i>Undefined</i>;
    }

    return (
        <>
            <Header />
            <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-300 text-white" data-image-src="/assets/img/bg1.jpg" style={{ backgroundImage: "url('/assets/img/bg1.jpg')" }}>
                <div className="container pt-14 pt-md-15 pb-4 pb-md-3">
                    <div className="row gx-lg-9">
                        <div className="col-lg-9">
                            <div className="app-avatar">
                                <img className="avatar" src={"/assets/img/img_avatar_dark.svg"} alt={'My Account'} />
                            </div>
                            <div className="app-title">
                                <h2 className="fs-15 text-opacity60">{`${user?.name}'s Profile` }</h2>
                                <h3 className="display-5 mf-300 b-0 text-white mb-1">{t('My Account')}</h3>
                            </div>
                        </div>
                        <div className="col-lg-3" style={{ display: 'none' }}>
                            <div className="search-form mt-2 mt-md-1 sch-box">
                                <input id="" type="text" className="form-control sch-box" placeholder="Search..." />
                                <label htmlFor="">Search...</label>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="wrapper">
                <div className="container py-15 py-md-6 pb-13 tb-account">
                    <MyMenu menu="profile" user={user}/>
                    <section className="wrapper">
                        <h2>{t('프로필')}</h2>
                        <div className="table-responsive mt-4">
                            <table className="table table-order">
                                <tbody>
                                    <tr>
                                        <td className="ps-0"><strong className="text-dark">{t('이름')}</strong></td>
                                        <td className="pe-0" colSpan="2">
                                            <p className="pb-2 pb-md-0">{user?.name}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ps-0"><strong className="text-dark">{t('이메일')}</strong></td>
                                        <td className="pe-0">
                                            <p>{user?.email}</p>
                                        </td>
                                        <td className="pe-0 text-end">
                                            <a href="./.html" className="btn btn-xs btn-soft-dark rounded-pill btn-x-lg">수정</a>
                                        </td>
                                    </tr>
                                    <tr style={{ display: "none" }}>
                                        <td className="ps-0"><strong className="text-dark">Email</strong></td>
                                        <td className="pe-0 td-input">
                                            <input type="email" className="form-control" placeholder="Email *" id="email" />
                                        </td>
                                        <td className="pe-0 text-end">
                                            <a href="./.html" className="btn btn-xs btn-dark rounded-pill btn-x-lg">취소</a>
                                            <a href="./.html" className="btn btn-xs btn-primary rounded-pill btn-x-lg">저장</a>
                                        </td>
                                    </tr>
                                    <tr style={{ display: "none" }}>
                                        <td className="ps-0"><strong className="text-dark">Password</strong></td>
                                        <td className="pe-0">
                                            <p>******</p>
                                        </td>
                                        <td className="pe-0 text-end">
                                            <a href="./.html" className="btn btn-xs btn-soft-dark rounded-pill btn-x-lg">수정</a>
                                        </td>
                                    </tr>
                                    <tr style={{ display: "none" }}>
                                        <td className="ps-0"><strong className="text-dark">Password</strong></td>
                                        <td className="pe-0 td-input">
                                            <div className="row col-md-12">
                                                <div className="col-md-6">
                                                    <input type="password" className="form-control mb-2 mb-md-0" id="password" placeholder="Password *" value="" required />
                                                </div>
                                                <div className="col-md-6">
                                                    <input type="password" className="form-control" id="passwordConfirm" placeholder="Confirm Password *" value="" required />
                                                </div>
                                            </div>
                                            <div className="invalid-feedback">Password fields is empty</div>
                                        </td>
                                        <td className="pe-0 text-end">
                                            <a href="./.html" className="btn btn-xs btn-dark rounded-pill btn-x-lg">취소</a>
                                            <a href="./.html" className="btn btn-xs btn-primary rounded-pill btn-x-lg">저장</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ps-0"><strong className="text-dark">{t('국가')}</strong></td>
                                        <td className="pe-0">
                                            <p>{country}</p>
                                        </td>
                                        <td className="pe-0 text-end">
                                            <a href="./.html" className="btn btn-xs btn-soft-dark rounded-pill btn-x-lg">수정</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ps-0"><strong className="text-dark">{t('마지막 로그인')}</strong></td>
                                        <td className="pe-0" colSpan="2">
                                            <p className="pb-2 pb-md-0">{moment(user?.lastlogged).format('LL')}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ps-0"><strong className="text-dark">{t('가입일')}</strong></td>
                                        <td className="pe-0" colSpan="2">
                                            <p className="pb-2 pb-md-0">{moment(user?.updated).format('LL')}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="ps-0"><strong className="text-dark">{t('Receive Marketing Information')}</strong></td>
                                        <td className="pe-0" colSpan="2">
                                            <SettingAccountMarketing></SettingAccountMarketing>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                </div>
            </section>
            <Loader isShow={showLoader} />
        </>
    )
}

const mapState = (state) => {
    const group = state.GroupReducer.item;

    return { group }
}

const mapDispatch = dispatch => ({
    GroupInfo: (_id) => dispatch(GroupAction.Get(_id))
});

export default connect(mapState, mapDispatch)(Profile);
