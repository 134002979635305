import React, { useEffect } from 'react';
import Header from "./Header"
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PrivacyEN from '../lib/privacy_en';
import PrivacyKO from '../lib/privacy_ko';
import PrivacyKOPrev from '../lib/privacy_ko_prev';
import PrivacyENPrev from '../lib/privacy_en_prev';

const PrivacyPolicy = () => {

    const { t, i18n } = useTranslation(['translation']);
    const { date } = useParams();

    useEffect(() => {
    }, [date]);

    return (
        <>
            <Header />
            <section className="wrapper image-wrapper text-white">
                <div className="sub-header-none"></div>
            </section>
            {!date && <>
                {i18n.language === "en" && <PrivacyEN />}
                {i18n.language === "ko" && <PrivacyKO />}
            </>}
            {date && <>
                {i18n.language === "en" && <PrivacyENPrev date={date} />}
                {i18n.language === "ko" && <PrivacyKOPrev date={date} />}
            </>}
        </>
    )
}

export default PrivacyPolicy;
