import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../history';
import Modal from 'react-bootstrap/Modal';

const EventModal = () => {
    const { t, i18n } = useTranslation(['translation']);
    const [show, setShow] = useState(true);

    useEffect(() => {
        if (localStorage.getItem('showEventModal') === 'false') {
            setShow(false);
          } else {
            setShow(true);
          }
    }, []);

    const handleClose = () => {
        localStorage.setItem('showEventModal', 'false');
        setShow(false);
    };
    
    return <>
        <Modal size="lg" centered show={show} onHide={() => setShow(false)} keyboard={false}>
            <div className="modal-content text-center" style={{ borderRadius: "0", background: "#21272f" }}>
                <div style={{ background: "url(/assets/img/RAYDENT_Designer_RAYDENT_Splint_Subscription_Service_Notification.png)", width: 800, height: 600, position: "relative"}} >
                    <button className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShow(false)}></button>
                    <div style={{ padding: "6px 22px", background: "#1f82b1", color: "white", left: 82, position: "absolute", top: 360, fontSize: 14, cursor: "pointer" }} onClick={() => history.push('/RAYDentDesigner')} >
                        Learn more
                    </div>
                    <div style={{ padding: "6px 22px", background: "#474789", color: "white", left: 450, position: "absolute", top: 360, fontSize: 14, cursor: "pointer" }} onClick={() => history.push('/RayDentSplint')} >
                        Learn more
                    </div>
                </div>
                <div className="text-end px-5 pt-1 pb-2 pb-md-1 pt-md-0">
                    <div className="fs-13 text-muted fw-normal py-2" style={{ cursor: "pointer" }} onClick={handleClose}>
                        <i className="uil uil-multiply me-1"></i>{t("Don't show this window again.")}
                    </div>
                </div>
            </div>
        </Modal>
    </>

}
export default EventModal;