import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { DoDB } from '../lib/ddb';
import { versionSort } from '../lib/function';
import FileExpIcon from "./FileExpIcon";
import Header from './Header';
import Select from "react-select";
import { Badge } from "react-bootstrap";
import { LANGUAGE } from "../constant/language";

const getPublishedApps = async () => {
    const params = {
        TableName: "rayrnd-applications",
        IndexName: "type-name-index",
        KeyConditionExpression: "#type = :type",
        FilterExpression: "#is_active=:t and #isdev=:n and #showHome=:t and attribute_exists(#manualRevisions[0])",
        ExpressionAttributeNames: { "#type": "type", "#is_active": "is_active", "#isdev": "isdev", "#showHome": "showHome", "#manualRevisions": "manualRevisions" },
        ExpressionAttributeValues: { ":type": "app", ":t": true, ":n": "N" }
    };
    const list = await DoDB("query", params);
    return list;
};

const AppManuals = ({ }) => {
    const { t } = useTranslation(['translation']);
    const [loading, setLoading] = useState(true);
    const [checkedApps, setCheckedApps] = useState(false);
    const [appList, setAppList] = useState([]);
    const [docList, setDocList] = useState([]);
    const [keywords, setKeywords] = useState({});
    const appsTitleList = useMemo(() => appList?.map(x => ({ value: x.name, label: x.title || x.name})), [appList]);
    const displayList = useMemo(() => {
        let _list = _.cloneDeep(docList);
        if (keywords?.fileName?.trim()?.length > 1) {
            const _fileName = keywords?.fileName?.trim()?.toLowerCase();
            _list = _list.filter(x => x.doc?.file_name?.toLowerCase()?.includes(_fileName));
        }
        if (keywords?.name) {
            _list = _list.filter(x => x._app_info.name === keywords?.name);
        }
        return _list;
    }, [docList, keywords]);

    useEffect(() => {
        getPublishedApps().then((items) => {
            const _items = _.orderBy(items, ["title"], ["asc"]);
            setAppList(_items);
            setCheckedApps(true);
        }).catch(() => {
            setAppList([]);
            setCheckedApps(true);
        });
    }, []);

    const getAllManuals = useCallback(async (apps) => {
        try {
            setDocList([]);
            let docs = [];
            for (const _app of apps) {
                let manualRevisions = _app.manualRevisions || [];
                if (manualRevisions?.length === 0) {
                    continue;
                }
                manualRevisions = versionSort(manualRevisions, "revision");
                manualRevisions = manualRevisions.map(x => ({ ...x, _app_info: _app }))
                docs = [...docs, ...manualRevisions];
            }
            setDocList(docs);
        } catch (error) {
            console.log("ERROR[getAllManuals]", error?.response?.data || error?.toString());
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (checkedApps) {
            setLoading(true);
            getAllManuals(appList);
        }
    }, [checkedApps, getAllManuals]);

    return (
        <>
            <Header />
            <section className="wrapper app-ban-area d-flex" data-image-src="/assets/img/img_app_ban01.jpg" style={{ "backgroundImage": "url(/assets/img/img_app_ban01.jpg)", "height": "400px" }}>
                <div className="container align-items-center d-flex">
                    <div className="row pt-md-12">
                        <div className="col-lg-12 z-index-9">
                            <h2 className="display-3 mb-6 fw-normal text-white">eIFU - {t('show all manuals')}</h2>
                            <div className="fs-lg mb-6 text-white">
                                <p className="mb-0 opacity-75">{t("All manuals for public apps can also be viewed on this page.")}</p>
                                <p className="mb-0 opacity-75">{t("You can also view them on the app's detail page.")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="wrapper bg-apps apps-list">
                <div className="container py-12">
                    {loading && <>
                        {t("Checking the latest documents.")}
                    </>}
                    {!loading && <>
                        {docList?.length === 0 && <>
                            <div>{t("There are no manuals to display.")}</div>
                        </>}
                        {docList.length > 0 && <>
                            <div className="d-flex align-items-center justify-content-between">
                                <h3 className="mb-0">{t("Manuals")}</h3>
                                <div className="d-flex align-items-center gap-2">
                                    <Select 
                                        options={appsTitleList}
                                        value={appsTitleList.find(x => x.value === keywords.name) || ""}
                                        onChange={_data => setKeywords(prev => ({ ...prev, name: _data?.value }))}
                                        placeholder={t("Product Name")}
                                        isClearable={true}
                                        styles={{
                                            control: (styles) => ({
                                                ...styles,
                                                border: '1px solid rgba(8, 60, 130, 0.07)',
                                                height: '42px',
                                                backgroundColor: '#fefefe',
                                                fontSize: '0.75rem',
                                                fontWeight:'500 !important',
                                                color: '#60697b !important',
                                                borderRadius: '0.4rem',
                                                boxShadow: '0rem 0rem 1.25rem rgb(30 34 40 / 4%)',
                                                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;',
                                                minWidth: "300px",
                                            }),
                                        }}
                                    />
                                    <input 
                                        type="text" 
                                        value={keywords?.fileName || ""} 
                                        onChange={e => setKeywords(prev => ({ ...prev, fileName: e?.target?.value }))} 
                                        placeholder={t("Document Name")}
                                        className="form-control form-control-sm"
                                    />
                                </div>
                            </div>
                            
                            <table className="table table-order mt-2" style={{ borderTop: "1px solid #8574FF" }}>
                                <thead>
                                    <tr>
                                        <th>{t("Product")}</th>
                                        <th>{t("Revision")}</th>
                                        <th>{t("Document")}</th>
                                        <th>{t("Published")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {displayList.map(x => <tr key={x.file_name}>
                                        <td>{x._app_info?.title || x._app_info?.name}</td>
                                        <td>{x?.revision}</td>
                                        <td>
                                            <a href={x.doc.direct_link} target="_blank" className='text-decoration-none d-inline-flex align-items-center gap-3'>
                                                {x.manualLang && <Badge bg="primary">{LANGUAGE.find(y => y.code === x.manualLang)?.label || x.manualLang.toUpperCase()}</Badge>}
                                                {x.doc.file_name}
                                                <FileExpIcon
                                                    name={x.doc.file_name}
                                                    labelUppercase={true}
                                                    style={{ width: 30 }}
                                                />
                                            </a>
                                        </td>
                                        <td>{x.published ? moment(x.published).format("LL") : "-"}</td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </>}
                    </>}
                </div>
            </section>
        </>
    )
}

const mapState = (state) => {
    return {};
};

const mapDispatch = dispatch => ({});

export default connect(mapState, mapDispatch)(AppManuals);
