import axios from "axios";
import { DoDB } from "../../lib/ddb";

const getEnv = () => {
    if (location.href.indexOf("localhost") > -1) {
        return "dev-local";
    } else if (location.href.indexOf("raydevelop") > -1) {
        return "development";
    }
    return "production";
}

const getSupportBB = () => {
    if(getEnv() === "production")
        return "30a14621-19f7-427d-b2e5-438faf64ffc5";
    return '6c006132-2880-47c7-bab1-626d79bd945a';
}

const GetSupportBoardSectionById = async () => {
    const list = await DoDB("query",  {
        TableName: 'rayteams-manager-boards',
        KeyConditionExpression: '#_id=:_id',
        ExpressionAttributeNames: { '#_id': '_id' },
        ExpressionAttributeValues: { ':_id': getSupportBB() }
    })
    return list.length > 0 ? list[0] : {};
}

const GetSupportBoardAllContentsTop = async (lang, top) => {
    const list = await DoDB("scan",  {
        TableName: 'rayteams-manager-contents',
        FilterExpression: '#bid=:bid and begins_with(#sk, :sk)',
        ExpressionAttributeNames: { '#bid': 'bid', '#sk': 'sk' },
        ExpressionAttributeValues: { ':bid': getSupportBB(), ':sk' : "lang:" + lang }
    })
    if(list.length > 0){
        return list.sort((a, b) => a.read - b.read).slice(0, top);
    }else{
        return [];
    }
}

const GetSupportBoardAllSections = async (lang) => {
    const list = await DoDB("scan",  {
        TableName: 'rayteams-manager-contents',
        FilterExpression: '#bid=:bid and #lang=:lang and begins_with(#sk, :sk)',
        ExpressionAttributeNames: { '#bid': 'bid', '#lang': 'lang', '#sk': 'sk' },
        ExpressionAttributeValues: { ':bid': getSupportBB(), ':lang' : lang, ':sk' : "mn:" }
    })
    if(list.length > 0){
        return list;
    }else{
        return [];
    }
}

const GetSupportBoardSections = async (lang) => {
    const list = await DoDB("scan",  {
        TableName: 'rayteams-manager-contents',
        FilterExpression: '#bid=:bid and begins_with(#sk, :sk)',
        ExpressionAttributeNames: { '#bid': 'bid', '#sk': 'sk' },
        ExpressionAttributeValues: { ':bid': getSupportBB(), ':sk' : "mn:" }
    })
    if(list.length > 0){
        const items= list.filter(x => x.sk.split(":").length === 2 && !x.lang);
        return list.filter(x => x.sk.split(":").length === 3 && x.lang === lang).map(x => {
            const item = items.find(y => y.sk.split(":")[1] === x.sk.split(":")[1]);
            return {
                ...item,
                ...x
            }
        });
    }else{
        return [];
    }
}

const GetSupportBoardSection = async (section, lang) => {
    const list = await DoDB("scan",  {
        TableName: 'rayteams-manager-contents',
        FilterExpression: '#bid=:bid and #lang=:lang and begins_with(#sk, :sk)',
        ExpressionAttributeNames: { '#bid': 'bid', '#lang': 'lang', '#sk': 'sk' },
        ExpressionAttributeValues: { ':bid': getSupportBB(), ':lang' : lang, ':sk' : section + ":" }
    })
    if(list.length > 0){
        return list;
    }else{
        return [];
    }
}

const GetSupportBoardContents = async (cid, lang) => {
    const list = await DoDB("scan",  {
        TableName: 'rayteams-manager-contents',
        FilterExpression: '#_id = :_id and #sk = :sk',
        ExpressionAttributeNames: { '#_id': '_id', '#sk': 'sk' },
        ExpressionAttributeValues: { ':_id': cid, ':sk' : "lang:" + lang }
    })
    return list.length > 0 ? list[0] : {};
}

const GetSupportBoardSectionItems = async (menu, lang) => {
    const list = await DoDB("scan",  {
        TableName: 'rayteams-manager-contents',
        FilterExpression: '#bid=:bid and #sk=:sk and begins_with(#menu, :menu)',
        ExpressionAttributeNames: { '#bid': 'bid', '#menu': 'menu', '#sk': 'sk' },
        ExpressionAttributeValues: { ':bid': getSupportBB(), ':sk' : "lang:" + lang, ':menu' : menu + ":" }
    })
    return list;
}

const GetList = async (bid) => {
    const list = await DoDB("query",  {
        TableName: 'rayteams-manager-contents',
        IndexName: 'bid-sk-index',
        KeyConditionExpression: '#bid=:bid',
        ExpressionAttributeNames: { '#bid': 'bid' },
        ExpressionAttributeValues: { ':bid': bid }
    })
    return list;
}

const GetItem = async (_id) => {
    const list = await DoDB("query",  {
        TableName: 'rayteams-manager-contents',
        KeyConditionExpression: '#_id=:_id',
        ExpressionAttributeNames: { '#_id': '_id' },
        ExpressionAttributeValues: { ':_id': _id }
    })

    return list;
}

const UpdateReadCount = async (_id, _sk) => {
	try {
		console.log((getEnv() === "production" ? 
            "https://api-manager-contents.rayteams.com" : 
            "https://api-manager-contents.raydevelop.com") + "/updatereadcount/" + _id + "/" + _sk);
		await axios.get((getEnv() === "production" ? 
            "https://api-manager-contents.rayteams.com" : 
            "https://api-manager-contents.raydevelop.com") + "/updatereadcount/" + _id + "/" + _sk);
	} catch (error) {
        console.log(error);
	}
}

export const BoardAction = {
    GetSupportBoardSectionById,
    GetSupportBoardContents,
    GetSupportBoardSection,
    GetSupportBoardSections,
    GetSupportBoardSectionItems,
    GetSupportBoardAllContentsTop,
    GetSupportBoardAllSections,
    UpdateReadCount,
    GetList,
    GetItem,
}
