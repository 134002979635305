import Privacy20230815 from "../contents/Privacy20230815_ko";
import Privacy20231211 from "../contents/Privacy20231211_ko";

const PrivacyKOPrev = ({ date }) => {

    return <>
        {date === "2023-12-11" && <Privacy20231211 />}
        {date === "2022-08-15" && <Privacy20230815 />}
    </>
}
export default PrivacyKOPrev;
