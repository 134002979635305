import moment from "moment/moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { AppConfig } from "../config";
import { APPCATEGORY, APPPERM } from "../constant/appconst";
import { LANGUAGE } from "../constant/language";
import { history } from "../history";
import { DownloadApp, formatBytes, getRealAppName, getVersion, moveTo, versionSort } from "../lib/function";
import { AppAction } from "../redux/action";
import AppHeader from "./AppHeader";
import BtnSubscription from "./BtnSubscription";
import RAYModal from "./Modal";
import RouteChangeTracker from "./RouteChangeTracker";
import _ from "lodash";

const App = ({ appInfo, userInfo }) => {
    const [parentApp, setParentApp] = useState({});
    const [imageSeq, setImageSeq] = useState(0);
    const [faqSeq, setFaqSeq] = useState(0);
    const [modal, setModal] = useState({ show: false });
    const [downloading, setDownLoading] = useState(false);
    const { t, i18n } = useTranslation(['translation']);
    const { appCode } = useParams();
    const [realAppName] = useState(getRealAppName(appCode));

    useEffect(() => {
        if (appCode === "ExocadConverter") {
            history.push("/ConverterForExocad");
        }
    }, [appCode]);

    const user = localStorage.getItem('user');

    const rLang = (langs) => {
        const r = [];
        langs?.map(x => {
            var ln = LANGUAGE.find(l => l.code === x)
            r.push(ln.label || x)
        });
        return r.join(", ");
    }

    const downloadStart = async () => {
        if (downloading)
            return;
        setDownLoading(true);
        await DownloadApp(realAppName);
        setDownLoading(false);
    }

    const checkParentApp = useCallback(async (appName) => {
        const pApp = await AppAction.GetAppItemByType(appName, "app");
        setParentApp(pApp || {});
    }, []);

    useEffect(() => {
        if (!appInfo?._basicInfo?.parentAppName) {
            setParentApp({});
            return;
        }
        checkParentApp(appInfo?._basicInfo?.parentAppName);
    }, [appInfo?._basicInfo?.parentAppName, checkParentApp]);

    RouteChangeTracker();

    const recentManuals = useMemo(() => {
        if (!Array.isArray(appInfo?._basicInfo?.manualRevisions) || appInfo?._basicInfo?.manualRevisions?.length === 0) {
            return [];
        }
        const manualRevisions = appInfo?._basicInfo?.manualRevisions;
        let revisions = manualRevisions.map(x => ({ revision: x.revision }));
        revisions = _.uniqBy(revisions, "revision");
        revisions = versionSort(revisions, "revision");
        const currentRevision = revisions[0].revision;
        return manualRevisions.filter(x => x.revision === currentRevision);
    }, [appInfo?._basicInfo?.manualRevisions]);

    return (
        <>
            <AppHeader />
            <section
                className="wrapper image-wrapper bg-overlay bg-overlay-400 bg-content app-bg-violet bg-position-top"
                data-image-src={appInfo?.bgImage}
                style={{ backgroundImage: appInfo?.bgImage ? `url("${appInfo?.bgImage}")` : "" }}>
                <div className="container py-16 py-md-13 pb-10 app-page">
                    <div className="row pt-md-12">
                        <div
                            className={`z-index-9 ${appInfo?.appIcon ? "col-lg-7" : "col-lg-12"}`}
                        >
                            <img src={appInfo?.appIcon} className="app-img-mobile" />
                            <h2 className="display-4 mb-3">{appInfo?.appTitle}</h2>
                            <div className="fs-lg mb-6" dangerouslySetInnerHTML={{ __html: appInfo?.intro?.replace(/<p/g, '<p className="mb-0 opacity-75"') }}></div>
                            <div className="row gy-3 gx-xl-8">
                                <div className="col-xl-6">
                                    <ul className="icon-list bullet-bg bullet-primary mb-0">
                                        <li><span><i className="uil uil-check"></i></span><span className="opacity-90">{t('제공자')}: {appInfo?.provider || "-"}</span></li>
                                        <li className="mt-3"><span><i className="uil uil-check"></i></span><span className="opacity-90">{t('카테고리')}: {APPCATEGORY.find(x => x.value === appInfo?.category)?.label || appInfo.category}</span></li>
                                    </ul>
                                </div>
                                <div className="col-lg-5">
                                    <ul className="icon-list bullet-bg bullet-primary mb-0">
                                        <li><span><i className="uil uil-check"></i></span><span className="opacity-90">{t('업데이트')}: {appInfo?.updated && moment(appInfo?.updated).format('YYYY. MM. DD')}</span></li>
                                        {appInfo?.fileSize && <>
                                            <li className="mt-3"><span><i className="uil uil-check"></i></span><span className="opacity-90">{t('크기')}: {appInfo?.fileSize}</span></li>
                                        </>}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {appInfo?.appIcon && <>
                            <div className="col-lg-5 app-image text-center">
                                <img src={appInfo?.appIcon} className="app-img-original" />
                                <img src={appInfo?.appIcon} className="app-img-blur" />
                            </div>
                        </>}
                    </div>
                </div>
            </section>

            <section className="wrapper bg-gray">
                <div className="container">
                    <div className="px-0 align-items-center alert alert-icon no-padd fw-light d-flex justify-content-between" role="alert">
                        <div>
                            {appInfo?._basicInfo?.inAppFeature && <>
                                {parentApp?.title && <>
                                    <div>
                                        {t("{{appTitle}} 앱은 {{parentAppTitle}}의 내부 기능입니다.", { appTitle: appInfo?._basicInfo?.title, parentAppTitle: parentApp.title })}
                                    </div>
                                </>}
                                {!parentApp?.title && <>
                                    <div>
                                        {t("{{appTitle}} 앱은 다른 앱의 내부 기능입니다.", { appTitle: appInfo?._basicInfo?.title })}
                                    </div>
                                </>}
                            </>}
                            {!appInfo?._basicInfo?.inAppFeature && <>
                                <div>
                                    {appInfo?.appTitle} <b>{getVersion(appInfo?.version)}</b> has been released. Check out the <a href="#" className="alert-link hover" onClick={(e) => moveTo(history, e, `/${appCode}/releasenotes`)}>Release Notes</a>.
                                </div>
                            </>}
                        </div>
                        {user && <BtnSubscription />}
                    </div>
                </div>
            </section>

            <section className="wrapper mt-10 mb-md-14 mb-0 app-page">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 app-main-info">
                            {appInfo?.screenshot?.length > 0 && <div className="mb-8">
                                <h5>{t('스크린샷')}</h5>
                                <div className="basic-slider owl-carousel dots-over owl-loaded owl-drag" data-nav="true" data-margin="5">
                                    <div className="owl-stage-outer owl-height">
                                        <div className="owl-stage"
                                            style={{
                                                transform: 'translate3d(0px, 0px, 0px)',
                                                transition: 'all 0.5s ease 0s',
                                                width: '1540px',
                                            }}>
                                            <div className="owl-item active" style={{ width: '825px', marginRight: '5px' }}>
                                                <div className="item"><img src={appInfo?.screenshot?.find(x => x.seq === imageSeq)?.url} className="rounded" alt="" /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="owl-nav">
                                        <button type="button" role="presentation" className="owl-prev"
                                            onClick={() => {
                                                if (imageSeq > 0)
                                                    setImageSeq(imageSeq - 1)
                                            }}>
                                            <i className="uil-arrow-left"></i>
                                        </button>

                                        <button type="button" role="presentation" className="owl-next" onClick={() => {
                                            if (imageSeq < appInfo?.screenshot?.length - 1)
                                                setImageSeq(imageSeq + 1)
                                        }}>
                                            <i className="uil-arrow-right"></i>
                                        </button>
                                    </div>
                                    <div className="owl-dots">
                                        {
                                            appInfo?.screenshot?.map((x, idx) => {
                                                return <button
                                                    key={'app_info_' + idx}
                                                    role="button"
                                                    className={"owl-dot" + (idx === imageSeq ? ' active' : '')}
                                                    onClick={() => setImageSeq(idx)}><span></span></button>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>}
                            {appInfo?.intro && <div className="mb-8">
                                <h5>{t('앱 정보')}</h5>
                                <div dangerouslySetInnerHTML={{ __html: appInfo?.intro }}></div>
                            </div>}
                            {appInfo?.movie?.length > 0 && <div className="mb-8">
                                <h5>{t('미디어')}</h5>
                                <div className="row">
                                    <div className="col-lg-12">
                                        {
                                            appInfo?.movie?.map((x, idx) => {
                                                return (
                                                    <div key={'movie_' + idx}>
                                                        <div>
                                                            <iframe width="100%" height="400" src={x.url.replace("https://www.youtube.com/watch?v=", "https://youtu.be/").replace('https://youtu.be', 'https://www.youtube.com/embed')} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="col-12">
                                        {/* <p><a href="#" className="underline">더 보기</a></p> */}
                                    </div>
                                </div>
                            </div>}
                            {appInfo?.feature?.filter(x => !x.head).length > 0 && <div className="mb-8">
                                <h5>{t('주요 기능')}</h5>
                                <div className="row">
                                    <div className="col-lg-12">
                                        {
                                            appInfo?.feature?.filter(x => !x.head).map((x, idx) => {
                                                return (
                                                    <div key={'feature_' + idx}>
                                                        <h3>{x.title}</h3>
                                                        <div dangerouslySetInnerHTML={{ __html: x.contents }}></div>
                                                        <div>
                                                            {x.mediaType === 'image' && <img src={x.mediaLink} />}
                                                            {x.mediaType === 'movie' && <iframe width="100%" height="400" src={x.mediaLink.replace('https://youtu.be', 'https://www.youtube.com/embed')} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="col-12">
                                        {/* <p><a href="#" className="underline">더 보기</a></p> */}
                                    </div>
                                </div>
                            </div>}
                            {/*<div className="mb-8">
                                <h5>{t('권한')}</h5>
                                <div className="row">
                                    <div className="col-lg-6">
                                        {appInfo?.appAuth?.length > 0 && <ul className="icon-list bullet-bg bullet mb-0">
                                            {
                                                appInfo?.appAuth?.map((x, idx) => <li key={'app_auth_' + idx}><span><i className="uil uil-check"></i></span><span>{APPPERM.find(y => y.code === x).label}</span></li>)
                                            }
                                        </ul>}
                                        {(!appInfo?.appAuth || appInfo?.appAuth?.length === 0) && <p>{t("No permissions required")}</p>}
                                    </div>
                                    <div className="col-12">
                                    </div>
                                </div>
                            </div>*/}
                            {appInfo?.faq?.length > 0 && <div className="mb-8">
                                <h5>{t('자주 찾는 질문')}</h5>
                                <div className="accordion accordion-wrapper mt-4" id="accordionExample-2">
                                    {
                                        appInfo?.faq?.map((x, idx) => {
                                            return (
                                                <div key={'faq_' + idx} className="card plain accordion-item">
                                                    <div className="card-header" id="headingOne-2">
                                                        <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne-2" aria-expanded="false" aria-controls="collapseOne-2" onClick={() => setFaqSeq(idx)}>
                                                            {x.q}
                                                        </button>
                                                    </div>
                                                    <div id="collapseOne-2" className={"accordion-collapse collapse" + (faqSeq === idx ? ' show' : '')} aria-labelledby="headingOne-2" data-bs-parent="#accordionExample-2">
                                                        <div className="card-body" dangerouslySetInnerHTML={{ __html: x.a }}></div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>}
                            {appInfo?.feature?.filter(x => x.head).length > 0 && <div className="mb-8">
                                {appInfo?.feature?.filter(x => x.head).map((x, idx) => {
                                    return (
                                        <div key={'feature_' + idx}>
                                            <h5>{x.title}</h5>
                                            <div dangerouslySetInnerHTML={{ __html: x.contents }}></div>
                                            <div>
                                                {x.mediaType === 'image' && <img src={x.mediaLink} />}
                                                {x.mediaType === 'movie' && <iframe width="100%" height="400" src={x.mediaLink.replace('https://youtu.be', 'https://www.youtube.com/embed')} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>}
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>}
                            {appInfo?.terms?.sub_cancel_notice && <div style={{ background: "#F6F7F9", padding: "24px" }} className="my-5">
                                <h6>{appInfo?.terms?.sub_cancel_notice.doc.title}</h6>
                                <div className="fs-13 text-gray-500 html-contents" dangerouslySetInnerHTML={{ __html: appInfo?.terms?.sub_cancel_notice.doc.contents }}></div>
                            </div>}
                        </div>
                        <div className="col-xl-4 app-side-bar">
                            <div className="accordion accordion-wrapper mt-md-3" id="accordionSimpleExample">

                                {/* {appCode === "DigitalSmileDesignInAppExportMockUp" && <>
                                    <div className="card plain accordion-item mb-2">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12">
                                                    <img className="img-fluid rounded mt-lg-7 mb-3" src="/assets/img/ban_mockup.jpg" alt="one-month-trial-promotion" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>} */}

                                {appCode === "DigitalSmileDesignInAppExportMockUp" && <>
                                    {moment().isBefore(moment("2024-03-01T09:00:00")) && (
                                        <div className="card plain accordion-item mb-2">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <img className="img-fluid rounded mt-lg-7 mb-3" src="/assets/img/ban_mockup.jpg" alt="one-month-trial-promotion" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>}

                                {(appInfo?.hasInstall || appInfo?.hasManual || recentManuals?.length > 0) && <>
                                    <div className="card plain accordion-item">
                                        <div id="divDownload" className="accordion-collapse collapse show" aria-labelledby="divDownload" data-bs-parent="#accordionSimpleExample">
                                            <div className="card-body">
                                                {
                                                    appInfo?.hasInstall &&
                                                    <p>
                                                        {user && <a href onClick={() => downloadStart()}
                                                            className="btn btn-md btn-primary rounded-pill text-white w-21 text-decoration-none">
                                                            {downloading ? t("Please wait a second...") : t('다운로드')}
                                                        </a>}
                                                        {!user && <a href onClick={() => history.push("/login")} className="btn btn-md btn-primary rounded-pill text-white w-21 text-decoration-none"> {t('다운로드')}</a>}
                                                    </p>
                                                }
                                                <ul>
                                                    {
                                                        appInfo?.hasInstall &&
                                                        (
                                                            <li>
                                                                <p className="fs-14">
                                                                    <i className="uil uil-info-circle"></i> {t('버전')} : {appInfo?.appDownloadInfo.version} <br />
                                                                    <i className="uil uil-info-circle"></i> {t('크기')} : {formatBytes(appInfo?.appDownloadInfo.fileSize)}<br />
                                                                    <i className="uil uil-info-circle"></i> {t('업데이트 날짜')} : {moment(appInfo?.appDownloadInfo.updated).format('YYYY. MM. DD')}</p>

                                                            </li>
                                                        )
                                                    }
                                                    
                                                    {recentManuals?.length > 0 && <>
                                                        <li>
                                                            <p className="mt-2 mb-1 text-dark mb-2"><strong>{t('매뉴얼')}</strong></p>
                                                            <p>
                                                                {
                                                                    recentManuals?.map((x, idx) => {
                                                                        return  <a key={`manual_${idx}`} href={x.doc?.direct_link} className="btn btn-md btn-dark rounded-pill text-white w-21 text-decoration-none mb-2">
                                                                            {t("User Manual({{lang}})", { lang: LANGUAGE.find(y => y.code === x.manualLang)?.label || x.manualLang?.toUpperCase()})}
                                                                        </a>
                                                                    })
                                                                }
                                                            </p>
                                                        </li>
                                                    </>}
                                                    {
                                                        recentManuals?.length === 0 && appInfo?.hasManual && (
                                                            <li>
                                                                <p className="mt-2 mb-1 text-dark mb-2"><strong>{t('매뉴얼')}</strong></p>
                                                                {/* <p className="fs-14">
                                                                        <i className="uil uil-info-circle"></i> {t('버전')} : 2.1.5.1 <br />
                                                                        <i className="uil uil-info-circle"></i> {t('크기')} : 3.75 MB<br />
                                                                        <i className="uil uil-info-circle"></i> {t('업데이트 날짜')}: 2023-03-06</p> */}
                                                                <p>
                                                                    {
                                                                        appInfo?.manualDownloadInfo?.map((x, idx) => {
                                                                            return (
                                                                                <a key={`manual_${idx}`} href={x.url} className="btn btn-md btn-dark rounded-pill text-white w-21 text-decoration-none mb-2">
                                                                                    {x.title}
                                                                                </a>
                                                                            )
                                                                        })
                                                                    }
                                                                </p>
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </>}

                                {appInfo?.version && <>
                                    <div className="card plain accordion-item">
                                        <div className="card-header" id="headingSimpleOne">
                                            <button className="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseSimpleOne" aria-expanded="true" aria-controls="collapseSimpleOne"> {t('최신 버전')}</button>
                                        </div>
                                        <div id="collapseSimpleOne" className="accordion-collapse collapse show" aria-labelledby="headingSimpleOne" data-bs-parent="#accordionSimpleExample">
                                            <div className="card-body">
                                                <ul>
                                                    <li>
                                                        <p>
                                                            <a className="text-primary" href='#' onClick={(e) => moveTo(history, e, location.pathname + '/releasenotes')}><u>{getVersion(appInfo?.version)}</u></a>
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </>}

                                {(appInfo?.providerLink || appInfo?.privacyLink) && <>
                                    <div className="card plain accordion-item">
                                        <div className="card-header" id="headingSimpleTwo">
                                            <div className="ms-4 fs-18 fw-bolder" style={{ color: "#8574ff" }}>{t('제공자 정보')}</div>
                                        </div>
                                        <div className="ms-4">
                                            <ul>
                                                {appInfo?.providerLink && <>
                                                    <li>
                                                        <p className="mb-0"><i className="uil uil-globe"></i> {t('웹 사이트')}</p>
                                                        <p><a href={appInfo?.providerLink} target="_blank">{appInfo?.providerLink}</a></p>
                                                    </li>
                                                </>}
                                                {appInfo?.privacyLink && <>
                                                    <li>
                                                        <p className="mb-0"><i className="uil uil-shield-check"></i> {t('개인정보 처리방침')}</p>
                                                        <p><a href={appInfo?.privacyLink} target="_blank">{appInfo?.privacyLink}</a></p>
                                                    </li>
                                                </>}
                                            </ul>
                                        </div>
                                    </div>
                                </>}
                                <div className="card plain accordion-item">
                                    <div className="card-header" id="headingSimpleTwo">
                                        <div className="ms-4 fs-18 fw-bolder" style={{ color: "#8574ff" }}>{t('지원 언어')}</div>
                                    </div>
                                    <div className="ms-4">
                                        <p>
                                            {rLang(appInfo?.languages)}
                                        </p>
                                    </div>
                                </div>
                                {appInfo?.tags?.length > 0 && <>
                                    <div className="card plain accordion-item">
                                        <div className="card-header" id="headingSimpleThree">
                                            <div className="ms-4 fs-18 fw-bolder" style={{ color: "#8574ff" }}>{t('태그')}</div>
                                        </div>
                                        <div className="ms-4">
                                            <p>
                                                {appInfo?.tags?.map((x, idx) => <span key={'tags_' + idx} className="badge bg-primary rounded-pill">{x.tag}</span>)}
                                            </p>
                                        </div>
                                    </div>
                                </>}
                                {appInfo?.terms?.sub_terms_of_use && <>
                                    <div className="card plain accordion-item">
                                        <div className="card-header" id="headingSimpleThree">
                                            <div className="ms-4 fs-18 fw-bolder" style={{ color: "#8574ff" }}>{t('약관')}</div>
                                        </div>
                                        <div className="ms-4">
                                            <p>
                                                <span onClick={() => setModal({
                                                    show: true,
                                                    msg: <div className="mb-3" style={{ fontSize: '36px', fontWeight: 700, lineHeight: "43.2px", color: "#343F52" }}>{appInfo?.terms?.sub_terms_of_use.doc.title}</div>,
                                                    feedback: <div style={{ background: "#F6F7F9", padding: "24px", maxHeight: "600px", overflowY: "auto" }} className="my-5">
                                                        <div className="fs-15 text-gray-500 html-contents" dangerouslySetInnerHTML={{ __html: appInfo?.terms?.sub_terms_of_use.doc.contents }}></div>
                                                    </div>,
                                                    btncomp: <>
                                                    </>,
                                                })} style={{ cursor: "pointer", fontWeight: 700 }}>{t('구독 이용 약관 보기')}</span>
                                            </p>
                                        </div>
                                    </div>
                                </>}
                            </div>
                        </div>
                        <RAYModal show={modal.show} msg={modal.msg} feedback={modal.feedback} btncomp={modal.btncomp} callback={() => setModal({ ...modal, show: false })} />
                    </div>
                </div>
            </section >

            {/*<section className="wrapper bg-light">
                <div className="ban-event">
                    <div className="bg-ban image-wrapper bg-full bg-image bg-overlay bg-overlay-light-600" data-image-src="./assets/img/bg_gift.png" style={{ backgroundImage: 'url("/assets/img/bg_gift.png")' }}>
                        <div className="card-body px-0">
                            <div className="container">
                                <div className="row align-items-center text-center text-lg-start">
                                    <div className="col-lg-5">
                                        <span className="badge bg-grape rounded-pill my-2">OPEN EVENT</span>
                                        <h1 className="display-6 mb-2 me-xl-5 me-xxl-0 text-grape">{t("무료 구독 기간을 한 달 더!")}</h1>
                                        <p className="fs-16 lh-sm mb-4"><span>{t("2024년 1월까지 구독을 신청 시,")}</span> <span>{t("무료 구독 기간을 총 2개월 드려요.")}</span></p>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="image-wrapper bg-full bg-image " data-image-src="./assets/img/bg_gift.png" style={{ backgroundImage: 'url("/assets/img/bg_gift.png")' }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>*/}
        </>
    )
}

const mapState = (state) => {
    const appInfo = state.AppReducer.appInfo;
    const userInfo = state.AuthReducer.user;
    return { appInfo, userInfo };
}

export default connect(mapState, null)(App);
