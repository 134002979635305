import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import SimpleLoader from "../components/SimpleLoader.js";
import { COUNTRIES } from '../constant/country';
import ComPayments from './ComPayments';
import MyMenu from './MyMenu';
import { AuthAction, PaymentsAction, SubscriptionAction } from "../redux/action";
import Header from "./Header";
import { history } from "../history";
import { DoDB } from "../lib/ddb";
import Loader from "./Loader";
import { useTranslation } from 'react-i18next';
import RAYModal from "./Modal";
import { SubscriptionConstant } from "../redux/reducer/SubscriptionReducer.js";
import SubRegisteredPcs from "./SubRegisteredPcs.js";

const Subscriptions = ({ subs, GetAllSubscriptions, subPcs, sub }) => {
    const { t, i18n } = useTranslation(['translation']);
    const user = JSON.parse(localStorage.getItem('user'));
    const [loading, setLoading] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [cancelModal, setCancelModal] = useState({ show : false });
    const [fbcancelid, setFBCancelId] = useState('');
    const [fbitems, setFBItems] = useState([]);
    const [votes, setVotes] = useState([]);
    //const [sub, setSub] = useState({});
    const [man, setMan] = useState(false);
    const [appList, setAppList] = useState([]);
    const [isProcessing, setIsProcessing] = useState({ show : false, type : "cancel"});
    const [payMethod, setPayMethod] = useState("");
    const fbmax = 3;
    const [subLicenseInfo, setSubLicenseInfo] = useState({});
    const [showLicenseKey, setShowLicenseKey] = useState(false);
    const [registedPcs, setRegistedPcs] = useState([]);
    const { _id } = useParams();
    const dispatch = useDispatch();

    // RAYFace Enterprise Version
    // /subscriptions/:_id
    // :_id - appid or rfev
    // appid - 구독 정보
    // rfev - 구독 관리
    useEffect(async () => {
        if (!user) {
            if(_id) {
                history.push(`/login/subscriptions/${_id}`);
            } else {
                history.push('/');
            }
        } else {
            setLoading(true);
            await GetAllSubscriptions();
            getSoftwares();
        }
    }, []);

    useEffect(() => {
        if(loading)
            setLoading(false);

        if(_id && subs.length > 0) {
            // subs 특정 할 수 있는 값 가져온다.
            const paramSub = subs.filter(x => (!x.canceled || x.lastrenewdata.product.licenseType !== "trial") && x.lastrenewdata.product.appitems.find(f => f == _id));
            if(paramSub && paramSub.length > 0) {
                manage(paramSub[0]);
            }
        }
    }, [subs]);

    useEffect(() => {
        if(appList.length > 0) {
            if(_id && subs.length == 0)  {
                history.push('/appList');
            }
        }
    }, [appList])

    useEffect(() => {
        if(sub._id) {
            console.log("sub._id : ===" ,sub);
            getPayLogBySK(sub._id, sub?.lastrenewkey);
            getFbCancel();
        }

    }, [sub._id]);

    useEffect(() => {
        
    }, [subLicenseInfo])

    useEffect(() => {
        
    }, [registedPcs])

    const getCountryName = (code) => {
        var c = COUNTRIES.find(x => x.countryCode === code);
        return c ? c.name : <i>Undefined</i>;
    }

    const getSoftwares = async (app) => {
        const list = await DoDB("query",  {
            TableName: 'rayrnd-applications',
            IndexName: 'type-name-index',
            KeyConditionExpression: '#type=:type',
            ExpressionAttributeNames: { '#type': 'type' },
            ExpressionAttributeValues: { ':type': "app" }
        })
        setAppList(list.filter(x => x.licenseId));
    }

    const getPayLogBySK = async (_id, lastrenewkey) => {
        const payItem = await DoDB("query", {
            TableName: 'rayteams-user',
            KeyConditionExpression: '#_id = :id and #sk = :sk',
            ExpressionAttributeNames: {'#_id': '_id', '#sk': 'sk' },
            ExpressionAttributeValues: { ':id': _id, ':sk': lastrenewkey }
        });
        payItem && payItem[0]?.cardNumber && setPayMethod(payItem[0]?.cardNumber);
    }

    const getAppInfo = (app) => {
        return appList.find(x => x.licenseId === app);
    }

    const getLicenseInfo = async (appId, sk) => {
        const licenseItem = await DoDB("query", {
            TableName: 'rayteams-license',
            KeyConditionExpression: '#_id = :_id and #sk = :sk',
            ExpressionAttributeNames: {'#_id': '_id', '#sk': 'sk' },
            ExpressionAttributeValues: { ':_id' : appId, ':sk': sk }
        });
        licenseItem.length > 0 && setSubLicenseInfo({...licenseItem[0], displayKey: licenseItem[0].sk.split(":").at(-1)});
        licenseItem.length > 0 && licenseItem[0]?.used && dispatch({type : SubscriptionConstant.GET_REGISTERED_PCS, items: licenseItem[0].used});
    };

    const manage = (item) => {
        // setSub({});
        // setSub({ ...item });
        setMan(true);
        setShowLicenseKey(false);
        dispatch({type: SubscriptionConstant.GET_SUB, item: item});
        const appsLcuse = item?.appsLcuse.filter(f => f.app == item?.lastrenewdata?.product?.appitems[0]);
        getLicenseInfo(appsLcuse[0]?.app, appsLcuse[0]?.lcKey)
    }

    const handleCopyClipBoard = async (copyText) => {
        try {
            await navigator.clipboard.writeText(copyText);
            alert("클립보드에 복사 되었습니다.");
        } catch (error) {
            alert("복사에 실패 하였습니다.");
        }
    }    

    const manageCancel = () => {
        //setSub({});
        setMan(false);
    }

    const subRefund = async () => {
        setCancelModal({ show : false, type : "refund" });
        const token = window?.localStorage?.getItem("userToken");
        if (!token) {
            alert("Missing user please login again.");
            return;
        }
        setIsProcessing({ show : true, type : "refund"});
        const ret = await PaymentsAction.RequestRefundSubscription({ sk : sub.sk }, token); 
        setIsProcessing({ show : false, type : "refund"});
        console.log(ret);
        if(ret){
            const retLic = await PaymentsAction.RevokeLicense(sub.sk.replace("product:", ""), sub._id, token); 
            console.log(retLic);
            reload({});
        }
    }

    //Cancel + Refund
    const subCancelAndRefund = async () => {
        if(subCancel(true)){
            subRefund();
        }
    }

    const voteUpdate = async () => {
        if(fbcancelid && votes.length > 0){
            await PaymentsAction.VoteProductFeedback( fbcancelid, votes );
        }
    }

    const subCancelStart = async (isnext = false) => {
        setCancelModal(false);
        const pg = sub.lastrenewdata?.pg;
        if (pg === "paypal") {
            const token = window?.localStorage?.getItem("userToken");
            if (!token) {
                alert("Missing user please login again.");
                return false;
            }
            const payload = {
                subId: sub.subid,
                productId: sub?.lastrenewdata?.product?._id,
                reason: "",
                reasons: votes, 
                token,
            };
            setIsProcessing({ show : true, type : "cancel"});
            const ret = await PaymentsAction.CancelPGSubscription(payload);
            if (!ret) {
                setIsProcessing({ show : false, type : "cancel"});
                alert(t("구독 취소에 실패했습니다. 잠시 후 다시 시도해 주세요."));
                return false;
            } else {
                await voteUpdate();
                setIsProcessing({ show : false, type : "cancel"});
                if(!isnext)
                    reload({});
            }
        } else if (pg === "toss") {
            const token = window?.localStorage?.getItem("userToken");
            if (!token) {
                alert("Missing user please login again.");
                return false;
            }
            const payload = {
                bId: sub.bid, 
                productId: sub?.lastrenewdata?.product?._id, 
                reason: "", 
                reasons: votes, 
                token,
                subId: null,
            }
            setIsProcessing({ show : true, type : "cancel"});
            const ret = await PaymentsAction.CancelPGSubscription(payload);
            if (!ret) {
                setIsProcessing({ show : false, type : "cancel"});
                alert(t("구독 취소에 실패했습니다. 잠시 후 다시 시도해 주세요."));
                return false;
            } else {
                await voteUpdate();
                setIsProcessing({ show : false, type : "cancel"});
                if(!isnext)
                    reload({});
            }
        }
        return true;
    }

    const subCancel = async () => {
        setCancelModal({ show : false, type : "cancel" });
        //subCancelStart();
    }

    const reload = () => {
        setCancelModal(false);
        setLoading(true);
        setMan(false);
        //setSub({});
        GetAllSubscriptions();
        getSoftwares();
        dispatch({type: SubscriptionConstant.GET_SUB, item: {}});
    }

    const msg = (iscancel) => {
        const expiredDate = moment(sub.expired);
        const nowDate = moment();
        const diffTimeValue = expiredDate.diff(nowDate);
        const leftDays = ~~moment.duration(diffTimeValue).asDays() + 1;
        
        if(iscancel){
            return (sub?.lastrenewdata?.product?.licenseType === "trial")
                ? <><div className="mb-3" style={{ fontSize : '36px', fontWeight : 700, lineHeight : "43.2px", color : "#343F52" }}>
                    {leftDays === 1 && <>
                        {t("무료 이용 기간이 {{day}}일 남았어요.", { day: leftDays } )}
                    </>}
                    {leftDays > 1 && <>
                        {t("무료 이용 기간이 {{days}}일 남았어요.", { days: leftDays })}
                    </>}
                    
                </div>
                    <div style={{ fontSize : '15px', fontWeight : 400, lineHeight : "18px", color : "##60697B" }}>{t("지금 구독 취소하시면 무료 평가판 이용이 즉시 중단됩니다.")}</div></>
                : <><div className="mb-3" style={{ fontSize : '36px', fontWeight : 700, lineHeight : "43.2px", color : "#343F52" }}>{t("어떤 점이 아쉬웠나요?")}</div>
                    <div style={{ fontSize : '15px', fontWeight : 400, lineHeight : "18px", color : "##60697B" }}>{t("더 나은 서비스를 위해 아래 설문에 응답해 주시면 큰 도움이 됩니다.")} <span style={{ color : "#C4C4C4" }}>{t('(최대 3개 선택 가능)')}</span></div></>
        }else{
            return <><div className="mb-3" style={{ fontSize : '36px', fontWeight : 700, lineHeight : "43.2px", color : "#343F52" }}>{t("환불 신청하시겠습니까?")}</div>
                <div style={{ fontSize : '15px', fontWeight : 400, lineHeight : "18px", color : "##60697B" }}>{t("환불 신청 시 되돌릴 수 없습니다.")}</div>
                <div className="my-3" style={{ fontSize : '15px', fontWeight : 400, lineHeight : "18px", color : "##60697B" }}>{t("환불은 영업일 기준 3일이 소요되며, 환불 요청일 기준으로 일할 계산되어 환불됩니다. 환불 요청하는 즉시 해당 소프트웨어를 사용할 수 없게 됩니다.")}</div>
            </>
        }
    }

    const buttons = (type) => {
        return type === "cancel" ? <div className="justify-content-end d-flex">
            <button className="btn btn-sm btn-gray py-2" onClick={() => setCancelModal({ show : false, type : "cancel" })} style={{ borderRadius: "24px" }}>{t("구독 유지")}</button>
            <button className="btn btn-sm btn-primary ms-2 py-2" onClick={() => subCancelStart()} style={{ borderRadius: "24px" }}>{t("구독 취소 계속")}</button>
        </div> : <div className="justify-content-end d-flex">
            <button className="btn btn-sm btn-gray py-2" onClick={() => setCancelModal({ show : false, type : "refund" })} style={{ borderRadius: "24px" }}>{t("취소")}</button>
            <button className="btn btn-sm btn-danger ms-2 py-2" onClick={() => subRefund()} style={{ borderRadius: "24px" }}>{t("환불 신청 계속")}</button>
        </div>;
    }

    const getRefundAmount = () => {
        if(!sub.lastrenewdata)
            return;

        const payed = sub.lastrenewdata.product.realprice;
        const payedunit = sub.lastrenewdata.product.punit;
        const lastpayed = sub.lastrenewkey.replace("paylog:", "");
        const cur = new Date().getTime();
        const alluse = sub.expired - lastpayed;
        const canused = sub.expired - cur;
        const days = Math.round(alluse / 3600 / 24 / 1000)
        const priceperday = payed / days;
        const remainday = Math.round(canused / 3600 / 24 / 1000);

        const refundAmount = (remainday - 1) * priceperday;
        return <strong>{payedunit + " " + refundAmount}</strong>;
    }

    const getStatusName = (x) => {
        if(x.rfstatus === "request")
            return <span style={{ color : "#E2626B", fontWeight : 700 }}>{t('환불 승인 중')}</span>;
        if(x.rfstatus === "done")
            return <span style={{ color : "#E2626B", fontWeight : 700 }}>{t('환불 완료')}</span>;
        if(x.rfstatus === "fail")
            return <span style={{ color : "#E2626B", fontWeight : 700 }}>{t('환불 거절')}</span>;
        if(x.canceled > 1)
            return <span style={{ fontWeight : 700 }}>{t('구독 취소')}</span>;
        if(x.lastrenewdata.product.licenseType === "trial")
            return <span style={{ fontWeight : 700 }}>{t('무료 평가판')}</span>;
        if(x.lastrenewdata?.pg == "RAY")
            return <span style={{ fontWeight : 700 }}>{t('구독')}</span>
        return <span style={{ fontWeight : 700 }}>{x.lastrenewdata.product.period === 365 ?  t('연간 구독') : t('월 구독')}</span> 
    }

    const getFbCancel = async () => {
        const token = window?.localStorage?.getItem("userToken");
        if (!token) {
            alert("Missing user please login again.");
            return;
        }
        if(!sub?.sk)
            return;
        const ret = await PaymentsAction.GetProductItem(sub.sk.split(":")[1]); 
        setFBCancelId(ret?.fbcancel || "");
        if(ret?.fbcancel){
            const retitems = await PaymentsAction.GetProductFeedback(ret.fbcancel); 
            setFBItems(retitems.find(x => x.lang === i18n.language) ? retitems.filter(x => x.lang === i18n.language) : retitems.filter(x => x.lang === "en"));
        }
    }

    const showModal = (type) => {
        setVotes([]);
        setCancelModal({ show : true, type : type });
    }

    const refundRes = () => {
        if(sub.rfstatus === "request"){
            return <>{t("환불 승인 중")}</>
        }else if(sub.rfstatus === "done"){
            return <>{sub.punit + " " + sub.rfamount + " " +  t("환불됨")}</>
        }else if(sub.rfstatus === "fail"){
            return <>{sub.rfcomment}</>
        }
        return <></>
    }

    return (
        <>

            <Header />
            <section className="wrapper image-wrapper bg-image bg-overlay bg-overlay-300 text-white" data-image-src="/assets/img/bg1.jpg" style={{ backgroundImage: "url('/assets/img/bg1.jpg')" }}>
                <div className="container pt-14 pt-md-15 pb-4 pb-md-3">
                    <div className="row gx-lg-9">
                        <div className="col-lg-9">
                            <div className="app-avatar">
                                <img className="avatar" src={"/assets/img/img_avatar_dark.svg"} alt={'My Account'} />
                            </div>
                            <div className="app-title">
                                <h2 className="fs-15 text-opacity60">{`${user?.name}'s Profile` }</h2>
                                <h3 className="display-5 mf-300 b-0 text-white mb-1">{t('My Account')}</h3>
                            </div>
                        </div>
                        <div className="col-lg-3" style={{ display: 'none' }}>
                            <div className="search-form mt-2 mt-md-1 sch-box">
                                <input id="" type="text" className="form-control sch-box" placeholder="Search..." />
                                <label htmlFor="">Search...</label>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="wrapper">
                <div className="container py-15 py-md-6 pb-13 tb-account">
                    <MyMenu menu="subscriptions" user={user}/>
                    <section className="wrapper">
                        {!man && <h2>{t('구독 관리')}</h2>}
                        {loading && t("Please wait a second...")} 
                        {(!loading && man) && <>
                            <div style={{ color : '#60697B', cursor : "pointer" }} onClick={() => setMan(false)}><i style={{ verticalAlign: "sub" }} className="fs-24 uil uil-arrow-left"></i>{t('목록으로 돌아가기')}</div>
                            <div className="d-flex w-100 justify-content-between">
                                <h2>{t('구독 정보')}</h2>
                                <div>
                                    {sub.lastrenewdata?.pg !== "RAY" && <>
                                        {!sub.canceled && <>
                                            <button 
                                                className={`btn btn-sm btn-danger ${isProcessing.show ? "disabled": ""}`} 
                                                onClick={() => !isProcessing.show && showModal("cancel")}
                                            >
                                                {isProcessing.show ? t("Please wait...") : t("구독 취소")}
                                            </button>
                                        </>}
                                        {(sub.canceled > 0 && !sub.rfstatus && subLicenseInfo?._id != "App-RAYFusion") && <>
                                            <button 
                                                className={`btn btn-sm btn-danger ${isProcessing.show ? "disabled": ""}`} 
                                                onClick={() => !isProcessing.show && showModal("refund")}
                                            >
                                                {isProcessing.show ? t("Please wait...") : t("환불 요청")}
                                            </button>
                                        </>}
                                    </>}
                                </div>
                            </div>
                            <div className="table-responsive mt-4" style={{
                                borderTop: "1px solid #8574ff",
                                borderColor: "#8574ff"
                            }}>
                                <table className="table table-order">
                                    <tbody>
                                        <tr>
                                            <td style={{ minWitdth : 150, width : 200 }}><strong className="text-dark">{t('상품명')}</strong></td>
                                            <td>
                                                <strong style={{ cursor : "pointer" }} onClick={() => history.push("/" + getAppInfo(sub.lastrenewdata.product.appitems[0]).name)}>{sub.lastrenewdata.product.title}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong className="text-dark">{t('구독 이용 기간')}</strong></td>
                                            <td>{moment(sub.started).format("LL")} ~ {moment(sub.expired).format("LL")}</td>
                                        </tr>
                                        {!sub.canceled && sub.paySuccess && sub?.lastrenewdata?.pg != "RAY" && <tr>
                                            <td><strong className="text-dark">{t("다음 결제 예정일")}</strong></td>
                                            <td>{moment(sub.expired).add(1, "day").format("LL")}</td>
                                        </tr>}
                                        {!sub.canceled && !sub.paySuccess && sub?.lastrenewdata?.pg != "RAY" && <tr>
                                            <td><strong className="text-dark">{t("결제 상태")}</strong></td>
                                            <td><strong className="text-red">{t("실패")}</strong></td>
                                        </tr>}
                                        <tr>
                                            <td><strong className="text-dark">{t("구독 상태")}</strong></td>
                                            <td>{getStatusName(sub)}</td>
                                        </tr>
                                        {subLicenseInfo?.displayKey && <tr>
                                            <td><strong className="text-dark">{t("라이선스 키")}</strong></td>
                                            <td className="p-0">
                                                <div className='fw-bolder'>
                                                    <div className="d-flex align-items-center gap-3">
                                                        <div style={{paddingLeft: 15}}>
                                                            <button 
                                                                className="btn btn-sm btn-primary py-1"
                                                                onClick={() => showLicenseKey ? setShowLicenseKey(false) : setShowLicenseKey(true)}>
                                                                {!showLicenseKey ? t("보기") : t("감추기")}
                                                            </button>
                                                        </div>
                                                        {showLicenseKey && <div>
                                                            {subLicenseInfo?.displayKey}
                                                            <button className="btn btn-sm btn-gray ms-2 py-2" style={{ borderRadius: "24px" }} onClick={() => handleCopyClipBoard(subLicenseInfo?.displayKey)}>{t("복사")}</button>
                                                        </div>}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>}
                                        {payMethod && <tr>
                                            <td><strong className="text-dark">{t("결제 수단")}</strong></td>
                                            <td>{payMethod}</td>
                                        </tr>}
                                        {sub.rfstatus && <tr>
                                            <td><strong className="text-dark">{t('환불')}</strong></td>
                                            <td>
                                                <div>{refundRes()}</div>
                                                <div className="small">{t("요청 일시")} : {moment(sub.rfreqtime).format("LLL")}</div>
                                                {sub.rfdonetime && <div className="small">{t("처리 일시")} : {moment(sub.rfdonetime).format("LLL")}</div>}
                                            </td>
                                        </tr>}
                                    </tbody>
                                </table>
                                <div className="d-flex justify-content-between">
                                </div>
                            </div>

                            <h3 className="mt-5">{t('결제 이력')}</h3>
                            <div className="table-responsive mt-4" style={{
                                borderTop: "1px solid #8574ff",
                                borderColor: "#8574ff"
                            }}>
                                <ComPayments sub={sub}/>
                            </div>
                            {subPcs && <>
                                <SubRegisteredPcs></SubRegisteredPcs>
                            </>}
                        </>}
                        {(!loading && !man) && <div className="table-responsive mt-4">
                            <table className="table table-order">
                                <thead>
                                    <tr>
                                        <th>{t("상품명")}</th>
                                        <th>{t("구독 상태")}</th>
                                        <th>{t("구독 이용 기간")}</th>
                                        <th>{t("다음 결제 예정일")}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {subs.filter(x => (!x.canceled || x.lastrenewdata.product.licenseType !== "trial")).length === 0 && <tr>
                                        <td className="text-center" colSpan="7">{t("구독중인 상품이 없습니다.")}</td>
                                    </tr>}
                                    {subs.filter(x => (!x.canceled || x.lastrenewdata.product.licenseType !== "trial")).map(x => {
                                        const app = getAppInfo(x.lastrenewdata.product?.appitems[0]);
                                        return <tr key={x.sk}>
                                            <td style={{ cursor : "pointer" }}><strong onClick={() => history.push("/" + app.name)}>{x.lastrenewdata.product.title}</strong></td>
                                            <td style={{ minWidth : "150px" }}>{getStatusName(x)}</td>
                                            <td><strong className="">{moment(x.started).format("LL")} ~ {moment(x.expired).format("LL")}</strong></td>
                                            <td><strong className="">{!x.canceled ? moment(x.expired).add(1, "day").format("LL") : '-'}</strong></td>
                                            <td className="text-end" style={{ minWidth : "70px" }}>
                                                <i className="fs-24 uil uil-arrow-circle-right" style={{ cursor : "pointer" }} onClick={() => { manage(x); console.log("selected subs :", x);}}></i>
                                            </td>
                                        </tr>
                                    } 
                                    )}
                                </tbody>
                            </table>
                        </div>}
                    </section>
                </div>
            </section>
            <Loader isShow={showLoader} />
            {sub && <>
                <div className="modal fade show" id="" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: cancelModal.show ? 'block' : "none" }}>
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setCancelModal({ show : false, type : "cancel" })}></button>
                                <div className="">
                                    {msg(cancelModal.type === "cancel")}
                                </div>
                                {cancelModal.type === "cancel" && <>
                                    {cancelModal.show && fbitems.length > 0 && <div style={{ background : "#F6F7F9", padding : "24px" }} className="my-5">
                                        {fbitems.sort((a, b) => a.order - b.order).map((x, idx) => <div className="form-check mb-3" key={"fb-" + idx}>
                                            <input className="form-check-input" type="checkbox" id={"fb-" + idx}  onChange={(e) => { 
                                                if(votes.length + 1 > 3 && e.target.checked)
                                                    e.target.checked = false;
                                                setVotes(e.target.checked ? [...votes, x.sk] : votes.filter(v => v !== x.sk))
                                            }} />
                                            <label className="form-check-label" style={{ fontWeight : 400, fontSize : "15px", lineHeight : "22px"}} htmlFor={"fb-" + idx}>{x.item}</label>
                                        </div>)}
                                    </div>}
                                </>}
                                {cancelModal.type === "refund" && <></>}
                                <div className="mt-6">
                                    {buttons(cancelModal.type)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show" style={{ display: cancelModal.show ? 'block' : "none" }}></div>
            </>}
            {isProcessing.show && <>
                <div className="modal fade show" id="" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: isProcessing.show ? 'block' : "none" }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="container text-center">
                                    <SimpleLoader title={isProcessing.type === "cancel" ? t("구독을 취소하고 있습니다.") : t("환불 신청을 요청하고 있습니다.")} message={
                                        <div>
                                            <div>{t('창을 닫거나 이동하지 마세요.')}</div>
                                            <div>{t('잠시만 기다려 주세요.')}</div>
                                        </div>
                                    } />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show" style={{ display: isProcessing.show ? 'block' : "none" }}></div>
            </>}
        </>

    )
}

const mapState = (state) => {
    const sub = state.SubscriptionReducer.sub;
    const subs = state.SubscriptionReducer.subs;
    const subPcs = state.SubscriptionReducer.subPcs;
    return { subs, subPcs, sub }
}

const mapDispatch = dispatch => ({
    GetAllSubscriptions: (_id) => dispatch(SubscriptionAction.GetAllSubscriptions(_id))
});

export default connect(mapState, mapDispatch)(Subscriptions);
