const PrivacyEN = () => {

    return (
        <section className="wrapper bg-gray">
            <div className="container pt-6 pt-md-12 pb-14">
                <div className="row gx-0">
                    <div className="col-xl-12">
                        <section className="wrapper policy">
                            <h1 className="display-3 mb-6">Privacy Policy</h1>
                            <div className="bg-light p-10 pt-5 pb-5 rounded">
            <div className="p-5">
                <h4 className="pt-5">1. Purpose of Our Privacy Policy</h4>
                <p>Ray Co., Ltd. (the "Company" "We/we" or "Our/our") is committed to compliance with the Personal Information Protection Act and Act on Promotion Of Information And Communications Network Utilization And Information Protection. We place great emphasis on protecting our users' personal information. Through this Privacy Policy (the/our “Privacy Policy”), we aim to inform users on how we use the personal information we collect from them and the measures we implement for its protection.</p>
                <p>This Privacy Policy applies to how the Company collects, uses, discloses, transfers and stores personal information from users during the software and website (https://www.rayteams.com) registration processes. This Privacy Policy applies to RAYTeams and associated services (the/our “Services”), including mobile web and applications.</p>
                <p>In the event of revisions to this policy in the future, we will notify users through website announcements or methods such as email.</p>

                <h4 className="pt-5">2.	Personal Information Collected and Collection Method</h4>
                <h6 className="pt-5">A.	Types of Personal Information Collected</h6>
                <p>When you register as a member to use our Services, the Company collects the minimum personal information necessary to provide the Services.</p>
                <ul>
                    <li>
                        The personal information collected from users at the time of registration includes the following:
                        <br/>
                        <p className="pt-2">For the purpose of managing our contact database, we collect your name, ID (email), phone number, name of dental clinic, name of dental laboratory, and address.</p>
                        <p className="pt-2">The Company may only access/collect information that you voluntarily provide via email or other means, and you will be notified in accordance with applicable laws concerning the collection of personal information about you. The Company will not sell or rent this information to anyone.</p>

                    </li>
                    <li>
                        The personal information collected from users during the use of our Services is as follows:
                        <br/>
                        <br/>
                        <table className="table">
                        <colgroup>
                        <col width="8%" />
                        <col width="12%" />
                        <col width="80%" />
                        </colgroup>
                        <thead>
                        <tr>
                        <th>Type</th>
                        <th>Category of Members</th>
                        <th>Collected/Used Information</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                        <td className="text-center" rowSpan="3">Mandatory</td>
                        <td className="text-center" rowSpan="2">All Members</td>
                        <td>Name, ID (email), password, phone number, dental clinic name, dental laboratory name, address, user’s mobile phone number</td>
                        </tr>
                        <tr>
                        <td>Name, ID (email), service usage record, IP address, access logs, billing and payment records</td>
                        </tr>
                        <tr>
                        <td>Dental Clinic/Laboratory Members</td>
                        <td>Name, ID (email), password, phone number, dental clinic name, dental laboratory name, address, user’s mobile phone number</td>
                        </tr>
                        </tbody>
                        </table>
                        <ul>
                            <li> If we collect additional personal information, at the time of collecting such information, we will notify users about “the personal information items collected, the purposes of the collection and use of the personal information, and the retention period of the personal information,” and obtain consent from the user.  </li>
                        </ul>
                    </li>
                    <li> In the process of using the Services, IP addresses, cookies, service usage records, device information and location information can be created and collected, specifically, 1) information related to the user can be automatically generated and collected in the process of using the Services, and 2) the unique information of the user’s device, which we safely transform and collect in such a way that the original value cannot be identified. The information collected in this way may or may not constitute personal information depending on whether it can be linked with personal information.
                    </li>
                    <li>The Company does not collect sensitive personal information (e.g., ideologies or beliefs, membership or withdrawal from a trade union or political parties, political opinions, health, sexual activities, etc.) that may significantly infringe on a user’s privacy. </li>
                </ul>
                <h6 className="pt-5">B.	Method of Personal Information Collection</h6>
                <p>The Company collects personal information through the following methods:</p>
                <ul>
                    <li>During member registration and service use, the user agrees to the collection of personal information and directly enters the information, and we collect the corresponding personal information.</li>
                    <li>Personal information can be collected during consultation through the customer service center via web pages, email, fax, phone calls, etc.</li>
                    <li>Personal information can be collected through paper documents at offline events or seminars.</li>
                    <li>We may receive personal information from external companies or organizations affiliated with the Company. In these cases, the affiliate will provide the personal information to the Company after obtaining user consent for personal information provision in accordance with the Personal Information Protection Act.</li>
                    <li>Information, such as device information, can be automatically generated and collected during the process of using PC web or mobile web/apps.</li>
                </ul>


            <h4 className="pt-5">3.	Purpose of the Collection and Use of Personal Information</h4>
            <p>The personal information collected by the Company will be utilized for the following purposes. The company uses personal information only for the purposes listed below, such as member management, development, provision and enhancement of Services, and the establishment of a secure internet environment for various services (including mobile web/apps).</p>
            <ul>
                <li>Member management, including confirmation of member registration, prevention of duplicate memberships, contact for the purpose of performing contractual obligations, confirmation of membership withdrawal, user identification, etc.</li>
                <li>Customer services, such as responding to member inquiries, providing service-related consultation, and processing complaints.</li>
                <li>Statistical and monitoring purposes.</li>
                <li>Improving our existing Services and content through demographic analysis, analysis of service visits and usage records, and service satisfaction surveys.</li>
                <li>Preserving records for dispute resolution, addressing complaints and user protection, and establishing marketing plans.</li>
                <li>Delivering notifications, such as amendments to the Privacy Policy or the Terms and Conditions (the/our “Terms and Conditions”) and service-related notices.</li>
                <li>Employing measures to restrict the use of the Services for members who have violated applicable laws and/or the Terms and Conditions, and to prevent or penalize the misuse of the Services, such as disrupting the smooth operation of the Services, account abuse and fraudulent transactions.</li>
                <li>Establishing a service usage environment that reassures users of the security, privacy and safety of the Services. </li>
            </ul>
            <p>The Company will only use your personal information to send marketing materials if prior consent has been obtained.</p>

            <h4 className="pt-5">4.	Delegation of Personal Information Processing</h4>
            <p>In general, the Company does not provide personal information to external parties without the prior consent of the user. However, personal information is provided 1) when the user has directly given prior consent for the provision of personal information to an external affiliate in order to use the external affiliate’s Services, 2) when the Company is required by applicable laws and regulations to disclose personal information, and 3) when the Company is required to disclose personal information to resolve an urgent threat to the life or safety of a user.</p>
            <p>The Company will not share your personal information with third parties outside the Company except 1) when necessary to fulfill your requests, 2) when required to provide your personal information in accordance with relevant laws and regulations, 3) when you have given us separate consent, 4) when necessary to be provided to foreign governments, etc. for the implementation of treaties and other international agreements, and 5) when necessary for criminal investigations, court proceedings and execution of protective dispositions</p>
            <p>The Company delegates some of the tasks necessary for the provision of the Services to an external company (the “Consignee”) and stipulates, manages and supervises the Consignee through a personal information processing and consignment agreement in order to ensure that the Consignee safely and properly processes your personal information. The consignment of your personal information is terminated upon member withdrawal and contract termination. </p>
            <p>The Consignee may be added or changed as necessary, and in this case, the changes will be reflected in this Privacy Policy and promptly notified to users. The Consignee and the contents of the delegated work are as follows: </p>
    
            <table className="table">
            <colgroup>
            <col width="20%" />
            <col width="40%" />
            <col width="40%" />
            </colgroup>
            <thead>
            <tr>
            <th>Consignee</th>
            <th>Contents of the Delegated Tasks</th>
            <th>Retention and Usage Period</th>
            </tr>
            </thead>
            <tbody>
                <tr>
                    <td className="text-center">Amazon Web Services, Inc. </td>
                    <td className="text-center">Data storage tasks</td>
                    <td className="text-center">Immediate disposal after the achievement of the purpose</td>
                </tr>
                <tr>
                    <td className="text-center">Toss Payments</td>
                    <td className="text-center">Payment Method Authentication and Processing (Bank Transfer, Credit Card, Mobile, Virtual Account Creation and Management, Bank Deposit, and Other Payment Methods, Refund Account Verification), Issuance of Cash Receipts.</td>
                    <td className="text-center">Immediate disposal after the achievement of the purpose</td>
                </tr>
                <tr>
                    <td className="text-center">PayPal Pte. Ltd.</td>
                    <td className="text-center">Payment Method Authentication and Processing (Bank Transfer, Credit Card, Mobile, Virtual Account Creation and Management, Bank Deposit, and Other Payment Methods, Refund Account Verification), Issuance of Cash Receipts.</td>
                    <td className="text-center">Immediate disposal after the achievement of the purpose</td>
                </tr>
            </tbody>
            </table>

            <h4 className="pt-5">5.	Provision of Personal Information to Third Parties</h4>
            <p>The Company will provide a user’s personal information to third parties only when the user has given consent or when it is required by relevant laws and regulations.</p>

            <h4 className="pt-5">6.	Disclosure of Personal Information Required by Law</h4>
            <p>The Company may disclose users' personal information when required by relevant laws or regulations or upon request from investigative agencies.</p>

            <h4 className="pt-5">7.	Transfer of Personal Information Overseas</h4>
            <p>The type of tasks delegated to overseas entities for the consignment of personal information processing is detailed below. For the purpose of providing the Services and improving user convenience, the Company may transfer and/or manage users' information overseas. In such cases, this Privacy Policy will still apply to the personal information transferred or managed overseas. Before transferring your personal information overseas, the Company will obtain your consent and take appropriate measures to ensure the protection of your personal information.</p>
            <p>The Company securely backs up (stores) data both domestically and internationally to provide uninterrupted Services even in the event of disasters or emergencies. The Company strictly monitors and controls the overseas data storage and management operations of the contracted overseas data storage provider, Amazon Web Services, Inc.  </p>
            <table className="table">
            <thead>
            <tr>
            <th>Personal Information Being Transferred </th>
            <th>Destination Country</th>
            <th>Frequency and Method of Transfer </th>
            <th>Transfer Recipient</th>
            <th>Contact Information of the Recipient and its Data Protection Officer</th>
            <th>Purpose of the Recipient's Use of Personal Information</th>
            <th>Retention and Usage Period of Personal Information by the Transfer Recipient</th>
            <th>Methods, Procedures, and Effects of Refusal to Transfer Personal Information</th>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td className="text-center">All personal information and log information collected during the use of the Services</td>
            <td>1. United States (Ohio, Virginia, California, Oregon)<br/>
            2. India (Mumbai)<br/>
            3. Korea (Seoul)<br/>
            4. Singapore<br/>
            5. Australia (Sydney)<br/>
            6. Japan (Tokyo)<br/>
            7. Canada<br/>
            8. Germany (Frankfurt)<br/>
            9. Ireland<br/>
            (10). United Kingdom (London)<br/>
            11. France (Paris)<br/>
            12. Sweden (Stockholm)<br/>
            13. Bahrain<br/>
            (14). Brazil (Sao Paulo)<br/>
            </td>
            <td className="text-center">Transfers occur frequently over the network during the process of providing the Services</td>
            <td className="text-center">Amazon Web Services, Inc.</td>
            <td className="text-center">AWS Korea Privacy<br/>aws-korea-privacy@amazon.com </td>
            <td className="text-center">Data storage for the provision of Services</td>
            <td className="text-center">Retained for the duration of the user's service membership period</td>
            <td className="text-center">You may refuse the transfer of personal information by refusing to agree to the Terms and Conditions. However, since agreement to the Terms and Conditions is essential to using the Services, you must agree to the Terms and Conditions to use the Services</td>
            </tr>
            </tbody>
            </table>

            <h4 className="pt-5">8.	Retention and Use Period of Personal Information</h4>
            <p>The Company promptly and completely destroys the personal information of the user at the time of membership withdrawal. However, if the Company receives consent from the user, the user’s personal information is retained for the period of consent. In addition, the Company will retain personal information when required by applicable laws and regulations. In such cases, the personal information will be retained for the relevant period as follows:</p>
            <ul style={{ listStyle : "none" }}>
                <li>1)	Records related to contracts or withdrawal of subscriptions: 5 years (Act on Consumer Protection in Electronic Commerce, etc.).</li>
                <li>2)	Records related to payment and supply of goods, etc.: 5 years (Act on Consumer Protection in Electronic Commerce, etc.).</li>
                <li>3)	Records related to consumer complaints or dispute resolution: 3 years (Act on Consumer Protection in Electronic Commerce, etc.).</li>
                <li>4)	Records on labelling and advertising: 6 months (Act on Consumer Protection in Electronic Commerce, etc.).</li>
                <li>5)	Users’ log records, access tracking data, and website visit records: 3 months (Protection of Communications Secrets Act).</li>
                <li>6)	Records related to electronic finance according to the Electronic Financial Transactions Act: 5 years (Framework Act on Electronic Documents and Electronic Transactions).</li>
            </ul>

            <h4 className="pt-5">9.	Procedure and Method of Personal Information Destruction</h4>
            <p>We promptly destroy personal information for which the purpose of collection and use has been achieved, such as member withdrawal, service termination or the expiration of the retention period consented to by the user, in such a way that such personal information cannot be reproduced. If the Company is required to retain personal information in accordance with relevant laws and regulations, the personal information is transferred to a separate database and is used only for the purpose of retention unless provided by relevant laws and is destroyed when the retention period expires. Electronic files are securely deleted using technical methods to prevent recovery and reproduction and printed materials are destroyed by methods such as shredding or incineration.</p>

            <h4 className="pt-5">10. Protection of Personal Information for Children Under 13</h4>
            <p>Our Company adheres to the principle of not collecting any information from children under the age of 13, or equivalent age as per applicable jurisdiction's law. Our internet website (including mobile applications), goods and the Services are intended to be provided to individuals above the respective age. Our internet website (including mobile applications) has age restriction features preventing children from using it, and we do not collect children's personal information through these features.</p>

            <h4 className="pt-5">11. Rights of the User (Or the User’s Legal Representative) With Respect to Personal Information and How to Exercise Them</h4>
            <p>You have the following data protection rights:</p>
            <ul>
                <li>You may request access to, correction or update to, suspension of processing or deletion of your personal information. However, the Company may decline your request if there are special provisions in relevant laws, a need to comply with legal obligations, a risk of harm to others, or the risk of unfairly infringing upon the property or benefits of others.</li>
                <li>You may raise objections to the processing of your personal information and may request a restriction on the processing of your personal information.</li>
                <li>You may request the Company to provide or transfer your personal information. If the Company has collected and processed your personal information with your consent, you may withdraw your consent at any time. Your withdrawal of consent does not affect the lawfulness of any processing performed prior to your withdrawal, nor does it affect the processing of your personal information based on legitimate processing grounds other than consent.</li>
                <li>You can exercise your rights without fear of being denied goods or the Services.
            If you wish to exercise these rights, you can send an email to support@rayteams.com or mail to "Ray Co., Ltd., 221, Pangyoyeok-ro, Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea" to request changes, modifications, or notifications of your information. We will respond to your request promptly.</li>
                <li>You can exercise your rights through a legal representative or a person who has received the delegation. In this case, you must submit a power of attorney according to Annex 11 of the "Public Notice on Personal Information Processing Methods (No. 2020-7)". Requests for correction and deletion of personal information cannot be made if the personal information is subject to collection requirements of applicable laws and regulations.</li>
            </ul>

            <h4 className="pt-5">12. Company's Actions to Protect Personal Information</h4>
            <p>Our Company is committed to managing your personal information securely and strives to protect personal information beyond the level required by the Personal Information Protection Act.</p>
            <p>We take administrative, technical, and physical measures to protect collected personal information from unauthorized access, use or disclosure. We have an internal management plan in place for data protection, and the personal information you provide is protected from unauthorized access, use or disclosure, and is securely stored on our servers.</p>
            <p>Additional technical measures include managing access rights to personal information processing systems, encrypting unique identifiers, installing security programs and preserving and encrypting access records.  </p>
            <ul>
                <li>We have established and implemented an internal management plan for personal information protection.<br/> We conduct an annual review of the implementation of our internal management plan, including the appointment of a privacy officer and matters related to the composition and operation of the personal information protection organization.</li>
                <li>We have taken measures to control access to personal information and limit access rights.<br/> We have established and implemented standards for granting, changing, and deleting access rights to personal information processing systems to block illegal access to personal information, and we have installed and operate an intrusion prevention system. We also minimize unauthorized disclosure of personal information by separating external internet and internal networks on work PCs of employees who can download personal information from personal information processing systems.</li>
                <li>We have taken encryption measures to safely store and transmit personal information.<br /> In addition to passwords and unique identifiers required by law, we also encrypt additional information such as emails, addresses, and phone numbers. We also safely transmit and receive personal information over the network through encrypted communication.</li>
                <li>We have taken measures to keep personal information access records and prevent forgery and alteration.<br /> We keep and manage the access records of personal information handlers to the personal information processing system, and regularly check access records to prevent misuse, abuse, loss, forgery, alteration, etc., of personal information, and we keep these access records securely so that they are not forged, altered, stolen or lost.</li>
                <li>We have installed up-to-date security programs for the protection of personal information.<br /> We regularly back up data to prevent the destruction of personal information, and we use the latest antivirus programs to prevent personal information or data from being leaked or damaged.</li>
                <li>We have taken physical measures for the safe storage of personal information.<br /> We install systems in areas where access is controlled from the outside to prevent personal information from being leaked or damaged by hacking or computer viruses, and we establish and operate access control procedures.</li>
                <li>We operate a dedicated organization for personal information protection.</li>
            </ul>

            <h4 className="pt-5">13. Installation, Operation and Refusal of Automatic Personal Information Collection Devices</h4>
            <p>Our Company wishes for you to access our Services in the easiest, most efficient, and most useful way possible. To this end, we use cookies and similar technologies to enhance the user environment and improve site security.</p>
            <p>A cookie is a small text file that is automatically created on your device when you visit almost any website. These cookies are stored by your internet browser and include basic information about your internet usage. When you revisit a website, the website recognizes your device through the cookies sent from your browser to the website, thereby improving the user environment by providing personalized content. Also, we use cookies to store your login information, so you do not need to re-enter your login details each time you use our Services. We can also understand your preferences through other cookies.</p>
            <p>We only use essential cookies necessary to access and navigate the website and to use all its features. Without these cookies, the website will not function correctly, and you will not be able to use certain crucial features. For example, we use cookies to maintain your login status during your visit, so you do not need to log in repeatedly to access other pages on the site. Also, we may use essential cookies to detect and prevent fraudulent activities. As essential cookies are necessary to access and use the functions of the website, they cannot be deactivated through cookie consent tools.  </p>

            <h4 className="pt-5">14. Privacy Officer and Receipt of Access Request/ Contact Information </h4>
            <p>Our Company has designated a Privacy Officer (the/our “Privacy Officer”) as follows to protect your personal information and handle complaints related to personal information. You can report all personal information protection-related complaints that arise while using our Company's Services to our Privacy Officer. The Privacy Officer will promptly and thoroughly respond to your reports.</p>

            <strong>Privacy Officer</strong>
            <ul>
                <li className="pt-1">Name: Lee Min-kyu</li>
                <li>Department and Position: Software Development Division (Director)</li>
                <li>Email: privacy@rayteams.com</li>
            </ul>
            <strong>Privacy Officer</strong>
            <ul>
                <li className="pt-1">Name: Seong-won Bang</li>
                <li>Department and Position: Software Development Division (Head)</li>
                <li>Phone: +82-31-605-1000</li>
                <li>Email: privacy@rayteams.com</li>
            </ul>

            <h4 className="pt-5">15. Data Protection Officer (DPO)</h4>
            <p>To protect users' personal information and smoothly handle user complaints related to personal information, we have designated a Data Protection Officer (the/our “DPO”) as follows:</p>
            <ul>
                <li className="pt-1">Data Protection Officer: Ohkims PC</li>
                <li>Address: 3F, Daegak Building, 5 Seocho-daero 78-gil, Seocho-gu, Seoul</li>
                <li>Contact: 02-538-5886</li>
                <li>Email: ybkim@ohkimslaw.com</li>
            </ul>
 
            <h4 className="pt-5">16. Infringement Remedy Resources</h4>
            <p>If you need to report or consult about personal information infringement, you can contact the following institutions:<br />
            Personal Information Dispute Mediation Committee: www.kopico.go.kr / 1833-6972<br />
            Personal Information Infringement Report Center of the Korea Internet & Security Agency: privacy.kisa.or.kr / 118 (without area code)<br />
            Cyber Investigation Department of the Supreme Prosecutors' Office: www.spo.go.kr / 02-3480-3573, 1301 (without area code)<br />
            Cyber Safety Guardian of the Korean National Police Agency: http://www.police.go.kr/www/security/cyber.jsp / 182 (without area code)</p>

            <h4 className="pt-5">17. Additional Rights Applicable to European Union (EU) Residents</h4>
            <p>If you are using our Services from the European Union (EU), we must have a separate legal basis to process your personal information.</p>
            <ul>
                <li>
                    Performance of Contract:<br />
                    We may need to use your information to meet our obligations under terms and conditions or other contractual obligations and policies necessary to provide our Services to you. If you do not provide this information, we may not be able to perform these contractual obligations or their performance may be delayed.
                </li>
                <li>
                    Consent:<br />
                    We use your information for cookies and similar technical information and for marketing purposes with your consent. You can withdraw your consent by contacting us using the information at the bottom of the Privacy Policy or by going to the unsubscribe link in any marketing communication you receive from us.
                </li>
                <li>
                    Legitimate interests: <br />
                    We may use your personal information for our legitimate interests: <br/>
                    <ul style={{ listStyle : "none" }}>
                        <li>i. Understanding how you use our Services;</li>
                        <li>ii. Improving our Services; and/or</li>
                        <li>iii. Maintaining the security of our Services.</li>
                    </ul>
                </li>
                <li>
                    Legal obligations and public interests: <br />
                    In some cases, we may have a legal obligation to collect your personal information or we may need your personal information to protect your important interests.
                </li>
            </ul>

            <h4 className="pt-5">18. Notification Before Revising Privacy Policy</h4>
            <p>If there are additions, deletions, or modifications to this Privacy Policy, we will provide you with a formal notice of the changes at least 7 days in advance. However, if there are significant changes affecting user rights, such as changes to the types of personal information collected or the purpose of use, we will provide a formal notice of these changes at least 30 days in advance and may also re-obtain user consent if necessary.</p>

            <p className="strong">
                Date of Announcement: December 10, 2023<br />
                Effective Date: December 18, 2023
            </p>
            <br />

            <div>View previous privacy policy</div>
            <ul>
                <li><a href="/privacy-policy/2023-12-11">1th Oct. 2023 ~ 10th Dec. 2023 - View privacy policy</a></li>
                <li><a href="/privacy-policy/2022-08-15">15th Aug. 2022 ~ 30th Sept. 2023 - View privacy policy</a></li>
            </ul>
                                                                                                                                                                                                                                                                                                                                                                                                                                                   </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
                                                                                                                                                                                                                                                                                                                                                                                                                                                   )
}

export default PrivacyEN;
